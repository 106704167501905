<template>
  <div class="social__networking-diagram">
            <LineChart  :chartData="chartData" :options="options"/>
  </div>
</template>

<script>
import LineChart  from '@/components/SocialNetworking/LineChart';
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "RatingDiagram",
  props: ['dataToVisualizeLabels', 'dataToVisualizeValues','dataType'],
  plugins: [ChartDataLabels],
  components: {
    LineChart 
  },
   data () {
    return {
      chartData: null,
      options: null,
    }
  },
  mounted() {
     this.fillData()
  },
  methods: {
    fillData() {
       this.chartData = {
          labels: this.dataToVisualizeLabels,
        //   labels:this.dataToVisualizeValues,
            datasets: [
              {
                label: this.dataType,
                backgroundColor: '#5e72e4',
                borderColor: '#5e72e4',
                fill: false,
                data: this.dataToVisualizeValues,
                datalabels: {   
                  display: false,               
                  color: 'white',
                  fontColor: "#CCC",
                }
              },
            ]
          }
      this.options =  {
        scales: {
                xAxes: [{
                  gridLines: {
                      display: false,
                      color: "rgb(73, 54, 141)"
                  },
                   ticks: {
                  fontColor: "#CCC", 
                },
                }],
                yAxes: [{
                  gridLines: {
                      display: true,
                      color: "rgba(73, 54, 141, 0.39)"
                  },
                  ticks: {
                  fontColor: "#CCC", 
                },
                }]
            },
        responsive: true,
        maintainAspectRatio: false,
        
        legend: {
                display: true,
                position: 'bottom',
                labels: {
                    fontColor: "rgb(243, 243, 243)",
                }
            },
        layout: {
          padding: {
            top: 30
          }
        },
        plugins: {
            datalabels: {
              color: 'black',
              labels: {
                title: {
                  font: {
                    weight: 'bold'
                  }
                }
              }
      }
        }
      }
    }
  },
  watch: {
    dataToVisualizeLabels: function() {
      this.fillData()
    }
  }
}
</script>

<style scoped>

</style>