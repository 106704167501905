<script>
   import { Line, mixins } from 'vue-chartjs';
   import ChartDataLabels from "chartjs-plugin-datalabels";
   const { reactiveProp } = mixins

   export default {
     name: 'LineChart',
     extends: Line,
     mixins: [reactiveProp],
     plugins: [ChartDataLabels],
     props: ['chartData', 'options'],

     mounted () {
       this.addPlugin(ChartDataLabels);
       this.renderChart(this.chartData, this.options)
     },
     components: {
       ChartDataLabels
     }
   }
 </script>

<style scoped>

</style>
