<template>
   <div class="statistic">
      <Header></Header>
    <div class="content content--extended">
      <div class="container-header">
        <p>Аналитика социальных сетей</p>
      </div>
      <div class="statistic__container">
        <statisticHeader :componentName = $options.name></statisticHeader>
        <div class="statistic__social-networking-diagram--container" v-if="dataValues.length">
            <div class="statistic__social-networking--barchart-filter">
                  <statistic-line-chart-period-filter></statistic-line-chart-period-filter>
            </div>
            <SocialNetworkingDiagram :data-to-visualize-labels="dataLabels"
                                     :data-to-visualize-values="dataValues"
                                     :data-type="dataType"
            ></SocialNetworkingDiagram>
        </div>
        <div v-if="!dataValues.length">
          <h3 style="color:black">Нет данных</h3>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Assets/Header";
import Footer from "@/components/Assets/Footer";
import statisticHeader from "@/components/SocialNetworking/statisticHeader";
import statisticLineChartPeriodFilter from "./SocialNetworking/statisticLineChartPeriodFilter";
import SocialNetworkingDiagram from "./SocialNetworking/SocialNetworkingDiagram";
import {mapState} from "vuex";

export default {
  name: "SocialNetworking",
  computed: mapState ({
      dataLabels: state => state.analyticsDashboard.dataLabels,
      dataValues: state => state.analyticsDashboard.dataValues,
      dataType: state => state.analyticsDashboard.dataType,
  }),
  components: {
      SocialNetworkingDiagram,
      Header,
      Footer,
      statisticLineChartPeriodFilter,
      statisticHeader
  },
  mounted() {
     this.$store.dispatch('analyticsDashboard/getStatisticData')
  },
  methods: {
      updatePeriod(periodDateList){
        this.dateFrom = periodDateList[0];
        this.dateTo = periodDateList[1];
        this.getStatisticData(this.requestQuery)
      }
  }
}
</script>

<style scoped>

</style>